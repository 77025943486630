import React from "react";
import styled from "styled-components";
import videoM30 from "../assets/videom30.mp4";

const TrainingPrograms = () => {
  return (
    <Container>
      {/* Primeiro componente */}
      <ProgramsWrapper>
        <ContentWrapper>
        <VideoWrapper>
            <Video controls>
              <source src={videoM30} type="video/mp4" />
              Seu navegador não suporta o vídeo.
            </Video>
            {/* <PlayButton /> */}
          </VideoWrapper>
        <TextContent>
        <Title>SEJA A SUA MELHOR VERSÃO! A MUDANÇA COMEÇA HOJE</Title>
        <Subtitle>
          O caminho para o seu melhor desempenho começa com pequenos passos. 
          Desafie seus limites, supere seus obstáculos e descubra o poder que você tem dentro de si.
        </Subtitle>
        <Button>COMECE AGORA</Button>
      </TextContent>
        </ContentWrapper>
      </ProgramsWrapper>
    </Container>
  );
};

export default TrainingPrograms;

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  background-color: #151515;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
`;

const ProgramsWrapper = styled.section`
  width: 100%;
  background-color: #151515;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    text-align: center;
  }
`;

const TextContent = styled.div`
  width: 55%;
  color: #fff;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Title = styled.h1`
  font-size: 40px;
  font-family: "Bebas Neue", sans-serif;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    font-size: 2rem;
    text-align: center;
  }
`;

const Subtitle = styled.p`
  font-size: 15px;
  margin-bottom: 40px;
  font-family: "Bebas Neue", sans-serif;
`;

const Button = styled.button`
  background-color: #ccff00;
  color: #000;
  padding: 15px 30px;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Bebas Neue", sans-serif;
`;

const VideoWrapper = styled.div`
  width: 40%;
  position: relative;
  border: 1px solid #fff;
  border-radius: 10px;
  padding: 15px;
  display: flex;
  justify-content: flex-end;

  @media (max-width: 768px) {
    width: 90%;
    margin-top: 20px;
    justify-content: center;
  }
`;

const Video = styled.video`
  width: 600px;
  height: 400px;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    width: 80%;
    height: auto;
  }
`;

const PlayButton = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(204, 255, 0, 0.8);
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &::before {
    content: "";
    width: 0;
    height: 0;
    border-left: 20px solid #000;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
  }
`;
