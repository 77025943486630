import React from 'react';
import styled from 'styled-components';
import { FaInstagram, FaWhatsapp } from 'react-icons/fa';


const Footer = () => {
  const handleInstagramClick = () => {
    window.open('https://www.instagram.com/m30fitness/', '_blank');
  };

  const handleWhatsAppClick = () => {
    window.open('https://wa.me/5531971934978', '_blank');
  };

  return (
    <FooterContainer>
      <Title>M30 Fitness</Title>
      <ContactContainer>
        <ContactItem onClick={handleInstagramClick}>
          <Icon as={FaInstagram} />
          <Label>Instagram</Label>
        </ContactItem>
        <ContactItem onClick={handleWhatsAppClick}>
          <Icon as={FaWhatsapp} />
          <Label>WhatsApp</Label>
        </ContactItem>
        <ContactItem>
          <Label>📍 Av. Otawa, 257, Jardim Canadá</Label>
        </ContactItem>
        <ContactItem>
          <Label>⏰ Horário: 6h às 22h - Segunda á Sexta / Sábado - 8h até 12h (Meio dia)</Label>
        </ContactItem>

        <Label>Domingos e feriados não abrimos</Label>
      </ContactContainer>
      <FooterBottom>
        <span>© {new Date().getFullYear()} M30 Fitness. Todos os direitos reservados.</span>
      </FooterBottom>
    </FooterContainer>
  );
};

export default Footer;
const FooterContainer = styled.footer`
  width: 100%;
  max-width: 1200px; /* Define a largura máxima para o conteúdo */
  background-color: #151515;
  color: white;
  text-align: center;
  // box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.5);
`;

const Title = styled.h2`
  margin-bottom: 10px;
  font-family: "Bebas Neue", sans-serif;
  font-size: 2rem;
`;

const ContactContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;
`;

const ContactItem = styled.div`
  margin: 8px 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 15px;
`;

const Icon = styled.span`
  margin-right: 10px;
  font-size: 15px;
  transition: color 0.3s;
  cursor: pointer;

  &:hover {
    color: #00aaff;
  }
`;

const Label = styled.span`
  font-size: 15px;
  font-family: "Bebas Neue", sans-serif;
`;

const FooterBottom = styled.div`
  margin-top: 30px;
  font-size: 15px;
  color: #ccc;
`;