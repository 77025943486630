import React from "react";
import styled from "styled-components";
import bg from "../assets/bg.png";
import bg0 from "../assets/bg0.jpeg";

const Header = () => {
  return (
    <Container>
      <Overlay />
      <Conteudo>
        <ContainerTexto>
          <Titulo>HOJE É O MELHOR DIA PARA COMEÇAR</Titulo>
          {/* <Button>SAIBA MAIS</Button> */}
        </ContainerTexto>
        <ContainerImagem>
          <Imagem src={bg} alt="Background Image" />
        </ContainerImagem>
      </Conteudo>
    </Container>
  );
};

export default Header;

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  height: 700px;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(${bg0});

  @media (max-width: 768px) {
    height: auto;
  }
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Cor preta com opacidade de 50% */
  z-index: 1; /* Garante que o overlay fique acima da imagem de fundo */
`;

const Conteudo = styled.div`
  width: 90%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  position: relative;
  z-index: 2; /* Fica acima do overlay */

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const ContainerTexto = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;

  @media (max-width: 768px) {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const ContainerImagem = styled.div`
  width: 30%;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Titulo = styled.h1`
  font-size: 4em;
  color: #fff;
  display: flex;
  align-items: center;
  font-family: "Bebas Neue", sans-serif;
  text-align: start;

  @media (max-width: 768px) {
    font-size: 2em;
    text-align: center;
  }
`;

const Button = styled.button`
  background-color: #fff;
  color: #000;
  padding: 10px 20px;
  font-size: 1em;
  border-radius: 10px;
  border: none;
  margin-top: 20px;
  align-self: flex-start;

  @media (max-width: 768px) {
    align-self: center;
  }
`;

const Imagem = styled.img`
  width: 100%;
  height: auto;
  object-fit: contain; /* Garante que a imagem não será cortada */

  @media (max-width: 768px) {
    width: 100%;
  }
`;
