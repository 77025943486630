import React from "react";
import styled from "styled-components";

const Aulas = () => {
  return (
    <Container>
      <Title>Aulas Coletivas</Title>

      <ContainerCard>
        <ClassCard>
          <ClassName>Aula Funcional</ClassName>
          <Divider />
          <ClassSchedule>Segunda, Quarta e Sexta</ClassSchedule>
          <ClassSchedule>07:15 - 08:00 / 19:00 - 19:45</ClassSchedule>
        </ClassCard>

        <ClassCard>
          <ClassName>Aula Jump</ClassName>
          <Divider />
          <ClassSchedule>Terça e Quinta</ClassSchedule>
          <ClassSchedule>20:15 - 21:00</ClassSchedule>
        </ClassCard>

        <ClassCard>
          <ClassName>Aula GAP</ClassName>
          <Divider />
          <ClassSchedule>Terça e Quinta</ClassSchedule>
          <ClassSchedule>07:15 - 07:45</ClassSchedule>
        </ClassCard>

        <ClassCard>
          <ClassName>Aula Abdominal</ClassName>
          <Divider />
          <ClassSchedule>Terça e Quinta</ClassSchedule>
          <ClassSchedule>19:00 - 19:20</ClassSchedule>
        </ClassCard>
      </ContainerCard>

      <DividerFooter />
    </Container>
  );
};

export default Aulas;

const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #151515;
`;

const ContainerCard = styled.div`
  width: 95%;
  color: #fff;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;

  @media (max-width: 768px) {
    width: 80%;
    padding: 20px;
    flex-direction: column;
    align-items: center;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 20%;
    height: 46%;
    border-top: 5px solid yellow;
    border-left: 5px solid yellow;

    @media (max-width: 768px) {
      width: 100%;
      height: 0;
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20%;
    height: 46%;
    border-bottom: 5px solid yellow;
    border-right: 5px solid yellow;

    @media (max-width: 768px) {
      width: 100%;
      height: 0;
    }
  }
`;

const Divider = styled.div`
  width: 90%;
  height: 1px;
  background-color: red;
`;

const Title = styled.h1`
  font-size: 2rem;
  color: #fff;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const ClassCard = styled.div`
  border-radius: 8px;
  margin: 12px;
  padding: 10px;
  width: 20%;
//   text-align: center;

  @media (max-width: 768px) {
    width: 80%;
  }
`;

const ClassName = styled.h2`
  font-size: 1rem;
  color: #fff;
`;

const ClassSchedule = styled.p`
  font-size: 14px;
  color: #777;
  margin-top: 5px;
`;

const DividerFooter = styled.div`
  width: 100%;
  height: 1px;
  background: #fff;
  margin-top: 50px;
  border-radius: 2px;
`;
