import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Importando CSS padrão do Swiper
import 'swiper/css/navigation'; // Importando CSS para navegação
import 'swiper/css/pagination'; // Importando CSS para paginação
import { Navigation, Pagination } from 'swiper/modules'; // Importando módulos

import bg1 from '../assets/bg1.jpg';
import bg2 from '../assets/bg2.jpg';
import bg3 from '../assets/bg3.jpg';
import bg4 from '../assets/bg4.jpg';
import bg5 from '../assets/bg5.png';
import bg6 from '../assets/bg6.png';

const Trainers = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Verifica o tamanho da tela
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768); // Define se a tela é menor que 768px
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Verifica o tamanho da tela na primeira renderização

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Container>
      <Titulo>Galeria</Titulo>
      <Cards>
        {isMobile ? (
          <Swiper
            modules={[Navigation, Pagination]} // Adicionando módulos
            spaceBetween={30}
            slidesPerView={1} // Mostra uma imagem por vez em telas pequenas
            navigation
            pagination={{ clickable: true }}
          >
            <SwiperSlide>
              <Imagem src={bg1} alt="Background Image 1" />
            </SwiperSlide>
            <SwiperSlide>
              <Imagem src={bg2} alt="Background Image 2" />
            </SwiperSlide>
            <SwiperSlide>
              <Imagem src={bg3} alt="Background Image 3" />
            </SwiperSlide>
            <SwiperSlide>
              <Imagem src={bg4} alt="Background Image 4" />
            </SwiperSlide>
            <SwiperSlide>
              <Imagem src={bg5} alt="Background Image 5" />
            </SwiperSlide>
            <SwiperSlide>
              <Imagem src={bg6} alt="Background Image 6" />
            </SwiperSlide>
          </Swiper>
        ) : (
          <>
            <Imagem src={bg1} alt="Background Image 1" />
            <Imagem src={bg2} alt="Background Image 2" />
            <Imagem src={bg3} alt="Background Image 3" />
            <Imagem src={bg4} alt="Background Image 4" />
            <Imagem src={bg5} alt="Background Image 5" />
            <Imagem src={bg6} alt="Background Image 6" />
          </>
        )}
      </Cards>
      {/* <Divider /> */}
    </Container>
  );
};

export default Trainers;

const Container = styled.section`
  width: 100%;
  max-width: 1200px;
  background-color: #151515;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const Cards = styled.div`
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  // Adicionando centralização para imagens em dispositivos móveis
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #fff; /* White divider to stand out against the gradient */
  margin: 0 auto 20px auto;
  border-radius: 2px;
`;

const Imagem = styled.img`
  width: 360px;
  height: 300px;
  margin: 10px;
  border-radius: 10px;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 768px) {
    width: 80%;
    height: auto;
    margin: 20px auto; // Centraliza a imagem no carrossel
    display: block; // Faz a imagem ser um bloco
  }
`;

const Titulo = styled.h1`
  color: #fff;
  font-size: 36px;
  margin-bottom: 20px;
  margin-top: 0;
  text-align: center;
  font-family: "Bebas Neue", sans-serif;

  @media (max-width: 768px) {
    width: 100%;
    font-size: 2rem;
    text-align: center;
  }
`;
