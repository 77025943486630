import React from 'react';
import styled from 'styled-components';
import exampleImage from '../assets/PersonalTeste.jpg'; // Importe a imagem correta aqui

const OurServices = () => {
  return (
    <Container>
      <Conteudo>
        <ContentWrapper>
          <Description>
            <DescriptionSubTitle>
              ALCANCE O CORPO FÍSICO QUE VOCÊ MERECE
            </DescriptionSubTitle>
            <DescriptionText>
              Nossa academia oferece uma ampla gama de serviços para atender a todas as suas necessidades de fitness. Desde programas de cardio até personal trainers especializados, estamos aqui para ajudá-lo a atingir seus objetivos. Contamos com equipamentos de ponta e um ambiente amigável e acolhedor. Junte-se a nós e descubra como podemos ajudá-lo a se tornar a melhor versão de si mesmo.
            </DescriptionText>
          </Description>
          <Image src={exampleImage} alt="Academia" />
        </ContentWrapper>
      </Conteudo>
    </Container>
  );
};

export default OurServices;

const Container = styled.section`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto; /* 
  // background-color: #151515;
  height: auto;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Conteudo = styled.div`
  width: 100%;
  display: flex;
  background-color: #151515;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  width: 95%;
  max-width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const Image = styled.img`
  width: 40%;
  margin: 20px;
  box-shadow: 15px 10px 10px yellow;

  @media (max-width: 768px) {
    width: 80%;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const Description = styled.div`
  width: 55%;
  padding: 20px;
  color: #fff;
  position: relative;

  @media (max-width: 768px) {
    width: 100%;
    padding-left: 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 20%; /* Defina o tamanho da linha no topo */
    height: 46%; /* A altura para a linha lateral esquerda */
    border-top: 5px solid yellow; /* Linha amarela no topo */
    border-left: 5px solid yellow; /* Linha amarela na esquerda */

    @media (max-width: 768px) {
      width: 100%; /* Linha no topo ocupando toda a largura em telas pequenas */
      height: 0; /* Esconde a linha lateral em telas pequenas */
    }
  }

  /* Linha no bottom e à esquerda */
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 20%; /* Defina o tamanho da linha no bottom */
    height: 46%; /* Altura para a linha lateral esquerda */
    border-bottom: 5px solid yellow; /* Linha amarela no bottom */
    border-right: 5px solid yellow; /* Linha amarela na direita */

    @media (max-width: 768px) {
      width: 100%; /* Linha inferior ocupando toda a largura em telas pequenas */
      height: 0; /* Esconde a linha lateral em telas pequenas */
    }
  }
`;

const DescriptionSubTitle = styled.h2`
  font-size: 3em;
  margin-bottom: 20px;
  font-family: "Bebas Neue", sans-serif;

  @media (max-width: 768px) {
    font-size: 2em; /* Reduz o tamanho da fonte em telas menores */
    padding: 20px;
  }
`;

const DescriptionText = styled.p`
  font-size: 1em;
  line-height: 1.6;
  width: 85%;
  font-family: "Bebas Neue", sans-serif;

  @media (max-width: 768px) {
    width: 100%;
    text-align: left;
    padding: 10px;
    font-size: 0.9em; /* Reduz o tamanho da fonte em telas menores */
  }
    cons
`;
