import React from 'react';
import styled from 'styled-components';

const PricingCards = () => {
  return (
    <PricingWrapper>
      <Titulo>Preços</Titulo>
      <Container>
        <Card>
          <CardTitle>Mensal</CardTitle>
          <Price>R$129,90</Price>
          <Divider />
          <FeatureList>
            {/* <FeatureItem>Lorem ipsum dolor sit amet</FeatureItem>
            <FeatureItem>Sed ut perspiciatis</FeatureItem>
            <FeatureItem>At vero eos et accusamus</FeatureItem>
            <FeatureItem disabled>Nam libero tempore</FeatureItem>
            <FeatureItem disabled>Sed ut perspiciatis</FeatureItem>
            <FeatureItem disabled>Sed ut perspiciatis</FeatureItem> */}
          </FeatureList>
          {/* <SubscribeButton>Subscribe</SubscribeButton> */}
        </Card>
        <Card>
          <CardTitle>Trimestral</CardTitle>
          <Price>R$114,90</Price>
          <Divider />
          <FeatureList>
            {/* <FeatureItem>Lorem ipsum dolor sit amet</FeatureItem>
            <FeatureItem>Sed ut perspiciatis</FeatureItem>
            <FeatureItem>At vero eos et accusamus</FeatureItem>
            <FeatureItem>Nam libero tempore</FeatureItem>
            <FeatureItem>Sed ut perspiciatis</FeatureItem>
            <FeatureItem disabled>Sed ut perspiciatis</FeatureItem> */}
          </FeatureList>
          {/* <SubscribeButton>Subscribe</SubscribeButton> */}
        </Card>
        <Card>
          <CardTitle>Semestral</CardTitle>
          <Price>R$99,90</Price>
          <Divider />
          <FeatureList>
            {/* <FeatureItem>Lorem ipsum dolor sit amet</FeatureItem>
            <FeatureItem>Sed ut perspiciatis</FeatureItem>
            <FeatureItem>At vero eos et accusamus</FeatureItem>
            <FeatureItem>Nam libero tempore</FeatureItem>
            <FeatureItem>Sed ut perspiciatis</FeatureItem>
            <FeatureItem>Sed ut perspiciatis</FeatureItem> */}
          </FeatureList>
          {/* <SubscribeButton>Subscribe</SubscribeButton> */}
        </Card>
        <Card>
          <CardTitle>Anual</CardTitle>
          <Price>R$99,90</Price>
          <Divider />
          <FeatureList>
            {/* <FeatureItem>Lorem ipsum dolor sit amet</FeatureItem>
            <FeatureItem>Sed ut perspiciatis</FeatureItem>
            <FeatureItem>At vero eos et accusamus</FeatureItem>
            <FeatureItem>Nam libero tempore</FeatureItem>
            <FeatureItem>Sed ut perspiciatis</FeatureItem>
            <FeatureItem>Sed ut perspiciatis</FeatureItem> */}
          </FeatureList>
          {/* <SubscribeButton>Subscribe</SubscribeButton> */}
        </Card>
      </Container>
    </PricingWrapper>
  );
};

export default PricingCards;

const PricingWrapper = styled.section`
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
    background-color: #151515;
  // padding: 40px 0;

  @media (max-width: 768px) {
    padding: 20px 0;
  }
`;

const Container = styled.div`
  //  width: 100%;  // Use 100% para que ocupe toda a largura disponível
  width: 95%;
  max-width: 1200px; // Define a largura máxima do container
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto; // Adiciona rolagem se necessário
  padding-bottom: 10px;
  background-color: #151515;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 10px;
  }
`;

const Titulo = styled.h1`
  color: #fff;
  font-size: 36px;
  margin-bottom: 40px;
  text-align: center;
  font-family: "Bebas Neue", sans-serif;

  @media (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 20px;
  }
`;

const Card = styled.div`
  background-color: #151515;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border: solid #333;
  margin: 0 10px;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 235px;
  color: #fff; /* Ensure text is visible on the gradient background */

  @media (max-width: 768px) {
    width: 300px;
    margin: 10px 0;
  }
`;

const CardTitle = styled.h3`
  font-size: 24px;
  margin-bottom: 20px;
  font-family: "Bebas Neue", sans-serif;

  @media (max-width: 768px) {
    font-size: 20px;
    margin-bottom: 10px;
  }
`;

const Price = styled.h2`
  font-size: 48px;
  margin-bottom: 10px;
  color: #ffd700;
  font-family: "Bebas Neue", sans-serif;

  @media (max-width: 768px) {
    font-size: 36px;
    margin-bottom: 5px;
  }
`;

const Divider = styled.div`
  width: 50px;
  height: 4px;
  background: #fff; /* White divider to stand out against the gradient */
  margin: 0 auto 20px auto;
  border-radius: 2px;

  @media (max-width: 768px) {
    margin: 0 auto 10px auto;
  }
`;

const FeatureList = styled.ul`
  list-style: none;
  padding: 0;
  margin-bottom: 20px;
  text-align: left;
`;

const FeatureItem = styled.li`
  font-size: 16px;
  margin: 10px 0;
  display: flex;
  align-items: center;
  font-family: "Bebas Neue", sans-serif;
  text-decoration: ${props => props.disabled ? 'line-through' : 'none'};
  color: ${props => props.disabled ? '#ddd' : '#fff'}; /* Light color for disabled items */

  &::before {
    content: ${props => props.disabled ? "'✖'" : "'✔️'"};
    margin-right: 10px;
    color: ${props => props.disabled ? '#ff4d4f' : '#28a745'}; /* Red for disabled, green for enabled */
  }
`;

const SubscribeButton = styled.button`
  background: #fff;
  color: #333;
  border: none;
  border-radius: 25px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;

  &:hover {
    background: #ddd;
  }
`;
