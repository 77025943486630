// src/App.js
import React from 'react';
import Header from './components/Header';
import OurServices from './components/OurServices';
import Trainers from './components/Trainers';
import PricingPlans from './components/PricingPlans';
import TrainingPrograms from './components/TrainingPrograms';
import Aulas from './components/Aulas';
import styled from 'styled-components';
import Footer from './components/Footer';

function App() {
  return (
    <ContainerGeral>
      <Header />
      <OurServices />
      <TrainingPrograms />
      <PricingPlans />
      <Trainers />
      <Aulas />
      <Footer />
    </ContainerGeral>
  );
}

export default App;

const ContainerGeral = styled.div`
  width: 100%;
  // height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1C1C1C;
`